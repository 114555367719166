body {
  max-width: 48em;
  margin-left: auto;
  margin-right: auto;
}

nav {
  border-bottom: 1px solid $base-border-color;
  margin: em(30) 0;

  ul {
    display: inline-block;
    margin-bottom: em(10);
  }
}

.article-list-title {
  margin-bottom: 0;
}

.article-list-date {
  font-size: $base-font-size * 0.85;
}
