// Solarized
$solarized-base03:   #002b36; //  8/4 brblack
$solarized-base02:   #073642; //  0/4 black
$solarized-base01:   #586e75; // 10/7 brgreen
$solarized-base00:   #657b83; // 11/7 bryellow
$solarized-base0:    #839496; // 12/6 brblue
$solarized-base1:    #93a1a1; // 14/4 brcyan
$solarized-base2:    #eee8d5; //  7/7 white
$solarized-base3:    #fdf6e3; // 15/7 brwhite
$solarized-yellow:   #b58900; //  3/3 yellow
$solarized-orange:   #cb4b16; //  9/3 brred
$solarized-red:      #dc322f; //  1/1 red
$solarized-magenta:  #d33682; //  5/5 magenta
$solarized-violet:   #6c71c4; // 13/5 brmagenta
$solarized-blue:     #268bd2; //  4/4 blue
$solarized-cyan:     #2aa198; //  6/6 cyan
$solarized-green:    #859900; //  2/2 green

// Typography
$base-font-family: 'Merriweather', 'Georgia', serif;
$heading-font-family: 'Lato', sans-serif;
$code-font-family: 'Ubuntu Mono', monospace;

// Font Sizes
$base-font-size: 1em;
$body-font-size: 1.15em;

// Line height
$base-line-height: 1.5;
$body-line-height: 2;
$heading-line-height: 1.2;
$code-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: $solarized-blue;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;

// Font Colors
$base-background-color: #fff;
$base-font-color: $dark-gray;
$action-color: $blue;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);
