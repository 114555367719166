body {
  -webkit-font-smoothing: antialiased;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $body-font-size;
  line-height: $body-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: $base-font-size;
  line-height: $heading-line-height;
  margin: $small-spacing 0 $small-spacing / 2 0;
}

h1 {
  font-size: $base-font-size * 1.75;
  margin: $small-spacing 0 $small-spacing 0;
}

h2 {
  font-size: $base-font-size * 1.5;
}

h3 {
  font-size: $base-font-size * 1.25;
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $action-color;
  text-decoration: none;
  transition: color 0.1s linear;

  &:active,
  &:focus,
  &:hover {
    color: darken($action-color, 15%);
  }

  &:active,
  &:focus {
    outline: none;
  }
}

hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

pre, code {
  font-family: $code-font-family;
  font-size: $base-font-size;
  line-height: $code-line-height;
}

code {
  line-height: $body-line-height;
}

.footnote {
  font-size: $base-font-size * .75;
}
