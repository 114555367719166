header {
  margin: 6em 0 3em 0;
  text-align: center;
  border-bottom: $base-border;
}

.devblog-header {
  h1 {
    font-family: $code-font-family;
    font-size: $base-font-size * 3;
    text-shadow: 0 1px 1px $solarized-base1;
  }

  a {
    color: $solarized-yellow;
  }

  h2, h3 {
    font-family: $base-font-family;
    font-weight: normal;
  }

  h2 {
    font-size: $base-font-size * 1.25;
    color: $solarized-base00;
  }

  h3 {
    font-style: italic;
    color: $solarized-base1;
  }
}

.devblog-title {
  font-size: 1.4em;
  font-weight: 700;
  margin: 0.75em 0 .25em 0;
  // letter-spacing: .15em;
}

.devblog-subtitle {
  color: $solarized-base00;
  font-family: $base-font-family;
  font-size: $base-font-size * 1.15;
  font-weight: normal;
}

.devblog-author {
  color: $solarized-base1;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-style: italic;
  font-weight: normal;
}
